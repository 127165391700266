<template>
  <v-row justify="space-between" no-gutters>
    <v-col cols="12">Confirmar contratação</v-col>
    <v-col cols="12" sm="12" md="12" lg="6">
      <v-text-field
        ref="name"
        label="Inserir Email de Acesso"
        :value="email"
        @input="$emit('update:email', $event)"
        required
        :rules="rules.email"
        hide-details
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="12" md="12" lg="5">
      <v-text-field
        ref="password"
        label="Inserir Senha de Acesso"
        :append-icon="pass ? 'visibility' : 'visibility_off'"
        @click:append="() => (pass = !pass)"
        :type="pass ? 'password' : 'text'"
        :rules="rules.password"
        :value="password"
        @input="$emit('update:password', $event)"
        required
        hide-details
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'servicos-adicionais-aprove',
  props: {
    email: {
      type: String,
      default: '',
      required: true,
    },
    password: {
      type: String,
      default: '',
      required: true,
    },
    error: {
      type: Array,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      pass: true,
      credentialsValid: false,
      loading: false,
      rules: {
        password: [
          (v) => !!v || 'Por favor, preencha a senha',
          (v) => !this.credentialsValid || 'Email ou senha invalido',
        ],
        email: [
          (v) =>
            /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            'Email precisa ser válido',
          (v) => !this.credentialsValid || 'Email ou senha invalido',
        ],
      },
    }
  },
}
</script>

<style lang="scss" scoped></style>
