import { render, staticRenderFns } from "./newServiceAprove.vue?vue&type=template&id=70b468b2&scoped=true"
import script from "./newServiceAprove.vue?vue&type=script&lang=js"
export * from "./newServiceAprove.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70b468b2",
  null
  
)

export default component.exports