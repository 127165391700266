<template>
  <v-row class="subheading">
    <v-col cols="12" align-self="center" v-if="!unlimited">
      <span class="font-weight-medium">Valor unitário: </span>
      <span>{{ service.value | currencyFromCents }}</span>
    </v-col>

    <v-col cols="12" v-if="!unlimited">
      <v-row no-gutters>
        <v-col cols="2" align-self="center">
          <span class="font-weight-medium">Quantidade:</span>
        </v-col>
        <v-col cols="6" sm="3" md="4" lg="3" align-self="center">
          <v-text-field
            class="mt-0 pt-0"
            @input="$emit('update:amount', Number($event))"
            :value="Number(amount)"
            type="text"
            hide-details
            v-mask="'##'"
            min="01"
            reverse
            :rules="rules.qtd"
          >
            <template v-slot:prepend>
              <v-icon color="red lighten-2" @click="decrement()">
                remove_circle
              </v-icon>
            </template>
            <template v-slot:append-outer>
              <v-icon color="info lighten-1" @click="increment()">
                add_circle
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="10 " align-self="center">
      <span class="font-weight-medium">Total: </span>
      <span>{{ localTotal | currencyFromCents }}</span> <br />
      <span class="">{{ textRule }}</span>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'servicos-adicionais',
  props: {
    service: {
      type: Object,
      default: () => {
        return {}
      },
      required: true,
    },
    amount: {
      type: Number,
      default: 1,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        qtd: [(v) => v >= 1 || 'A quantidade deve ser maior que 1'],
      },
    }
  },
  computed: {
    textRule() {
      if (this.notApplicable) {
        return null
      }
      if (this.unlimited) {
        return 'Serviço incluso no seu plano contábil. Não será cobrado.'
      }
      if (this.fixed && this.checkIfPassedLimit) {
        let text = `Você ultrapassou o limite de ${this.limit} contratações gratuitas em seu plano, por isso, ao realizar esta contratação, serão cobrados ${this.correctAmountForBilling}`
        text += this.correctAmountForBilling > 1 ? ' adicionais' : ' adcional'
        return text
      } else {
        return `Você possui ${this.limit} ${this.service.name} inclusas em seu plano \n Ao realizar esta contratação, está utilizando ${this.correctAmountForBilling} de ${this.limit} e o serviço não será cobrado.`
      }
    },
    correctValue() {
      return this.service.value
    },
    localTotal() {
      let sum = 0
      if (this.notApplicable) {
        sum = this.correctValue * this.amount
      }
      if (this.fixed && this.checkIfPassedLimit) {
        sum = this.correctValue * this.correctAmountForBilling
      }
      this.$emit('update:total', sum)
      return sum
    },
    correctAmountForBilling() {
      if (this.fixed && this.checkIfPassedLimit) {
        let totalRequest = Number(this.requestCount) + Number(this.amount)
        return this.requestCount > this.limit
          ? this.amount
          : totalRequest - this.limit
      }
      return this.amount
    },
    requestCount() {
      return this.service.requestCount
    },
    checkIfPassedLimit() {
      let totalRequest = Number(this.requestCount) + Number(this.amount)
      return this.requestCount > this.limit || totalRequest > this.limit
    },
    limit() {
      return this.service.limit
    },
    fixed() {
      return this.service.limitDemarcation === 'fixed'
    },
    notApplicable() {
      return this.service.limitDemarcation === 'not applicable'
    },
    unlimited() {
      return this.service.limitDemarcation === 'unlimited'
    },
    limitTypeTrasnlat() {
      return this.service.limitType === 'month' ? 'mês' : 'ano'
    },
  },
  methods: {
    increment() {
      this.$emit('update:amount', this.amount + 1)
    },
    decrement() {
      if (this.amount > 1) this.$emit('update:amount', this.amount - 1)
    },
  },
}
</script>

<style lang="scss" scoped></style>
