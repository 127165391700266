<template>
  <div class="ma-6">
    <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
    <s-new-service
      :service="additionalService"
      v-if="!loading && !isBlocked"
    ></s-new-service>
    <s-new-service-blocked :service="additionalService" v-if="isBlocked" />
  </div>
</template>

<script>
import SNewService from '@src/components/additionalServices/newService.vue'
import SNewServiceBlocked from '@src/components/additionalServices/newServiceBlocked.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'new-service-view',
  components: {
    SNewService,
    SNewServiceBlocked,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      additionalService: 'additionalService/additionalService',
    }),
    isBlocked() {
      if (!this.additionalService.plan) return
      if (!this.additionalService.plan.rule) return
      return this.additionalService.plan.rule.isBlocked
    },
  },
  methods: {
    getService() {
      if (!this.$route.params.idService) return
      this.$store
        .dispatch(
          'additionalService/getAdditionalService',
          this.$route.params.idService
        )
        .then((result) => {
          this.loading = false
        })
    },
  },
  mounted() {
    this.getService()
  },
}
</script>

<style lang="scss" scoped></style>
