<template>
  <div>
    <h1 class="text-h6 grey--text text--darken-3">
      O Serviço <strong>{{ service.name }}</strong> não esta disponivel no seu
      plano
    </h1>
    <a href="">Conheça os demais planos</a>
  </div>
</template>
<script>
export default {
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@design';

a {
  color: #0d5f18;
  text-decoration: none;
}
</style>
