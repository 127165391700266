<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '8'">
        <h1 class="text-h5 grey--text text--darken-3">
          {{ service.name }}
        </h1>
        <div class="subheading mt-6">
          <span class="font-weight-medium">Descrição</span>
          <p v-html="this.$options.filters.whiteSpace(service.description)"></p>

          <p class="mt-4">
            <span class="font-weight-medium"> Prazo em dias úteis: </span
            >{{ service.deadline }}
          </p>
        </div>

        <new-service-charge
          v-if="!isBlocked"
          :service="service"
          :amount.sync="orderService.qtd"
          :total.sync="orderService.total"
        />

        <div class="subheading mt-6" v-if="!isBlocked">
          <p class="font-weight-medium mb-1">Seu comentário:</p>
          <v-textarea
            ref="comment"
            label="O que está precisando?"
            v-model="orderService.comment"
            :rules="rules.comment"
            required
            auto-grow
          >
          </v-textarea>
        </div>

        <new-service-aprove
          class="mt-3"
          v-if="!isBlocked"
          :service="service"
          :email.sync="orderService.user.email"
          :password.sync="orderService.user.password"
        />

        <v-col cols="12" v-show="error" class="py-2">
          <p v-for="(item, index) in error" :key="index">
            <span class="error--text">
              <v-icon color="error" small> priority_high </v-icon>
              {{ item }}
            </span>
          </p>
        </v-col>

        <div
          class="d-flex flex-row mb-6 justify-space-between mt-10"
          v-if="!isBlocked"
        >
          <v-btn
            rounded
            color="grey darken-1"
            dark
            :to="{ name: 'ShoppingList' }"
          >
            Voltar
          </v-btn>
          <v-btn
            rounded
            color="success"
            dark
            @click="createOrderService()"
            :loading="loading"
          >
            Contratar
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import newServiceAprove from './newServiceAprove.vue'
import newServiceCharge from './newServiceCharge.vue'

export default {
  name: 'servicos-adicionais',
  props: {
    service: {
      type: Object,
      default: () => {
        return {}
      },
      required: true,
    },
  },
  components: {
    newServiceAprove,
    newServiceCharge,
  },
  data() {
    return {
      valid: true,
      pass: true,
      error: null,
      credentialsValid: false,
      loading: false,
      orderService: {
        qtd: 1,
        total: null,
        comment: '',
        user: {
          email: '',
          password: '',
        },
      },
      rules: {
        comment: [
          (v) => !!v || 'Por favor, nos conte o que você está precisando',
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      newOrderService: 'additionalService/orderService',
    }),
    isBlocked() {
      return this.service.isBlocked
    },
    correctValue() {
      return this.service.value
    },
    canHire() {
      if (this.service.isBlocked) {
        return false
      }
      if (
        this.service.limitDemarcation === 'not applicable' &&
        this.orderService.total === 0
      ) {
        return false
      }

      return true
    },
  },
  methods: {
    increment() {
      this.orderService.qtd++
    },
    decrement() {
      if (this.orderService.qtd > 1) this.orderService.qtd--
    },
    errorMessages() {
      this.$refs.form.$data.inputs.forEach((input) => {
        console.log(input)
        if (input.errorBucket.length > 0) this.error.push(...input.errorBucket)
      })
    },
    createOrderService() {
      this.error = []
      this.credentialsValid = false
      if (!this.$refs.form.validate()){
        this.errorMessages()
        return;
      }
      let data = {
        additionalServiceId: this.service.id,
        user: this.orderService.user.email,
        password: this.orderService.user.password,
        commentUser: this.orderService.comment,
        qtd: this.orderService.qtd,
      }
      this.loading = true
      this.$store
        .dispatch('additionalService/createOrderService', data)
        .then((res) => {
          this.loading = false
          this.$router.push({
            name: 'DetailService',
            params: { idOrderService: res.id },
          })
        })
        .catch((res) => {
          this.loading = false
          if (res.errors.hasOwnProperty('user')) {
            this.credentialsValid = true
            this.$refs.form.validate()
            this.error.push('Email ou senha invalido')
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
